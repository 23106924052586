import React, { Component } from "react";
import "./aboutpage.scss"
import { Container } from "reactstrap";
import Header from "../components/Header";

export default class AboutMePage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.revealText = this.revealText.bind(this)
        this.unrevealText = this.unrevealText.bind(this)
    }

    stoppedTyping() {
        this.setState({ animClass: "subtitle fadein" });
    }

    revealText(number, e) {
        e.target.innerText = this.state.links[number].name;
    }

    unrevealText(number, e) {
        e.target.innerText = this.state.links[number].encryptedName;
    }


    render() {
        return (
            <div>
                <Header name={"About me"} />
                <div id="about-me" className="content fade-in top-space-for-header">
                    <img style={{ width: "100%" }} src="img/hf4.jpg" className="img-seperator" alt={"Nope"}></img>
                    <div id="main">
                        <article id="about">
                            <div className="about-content container-fluid bg-b">
                                <Container>
                                    <h2>About me</h2>
                                    <p>Comming soon...</p>
                                </Container>
                            </div>
                        </article>
                        <article id="impressum">
                            <div className="about-content container-fluid bg-a">
                                <Container>
                                    <h2>Impressum</h2>
                                    <p>Angaben gemäß § 5 TMG</p>
                                    <p>Christoph Landsky <br />
                                        Scheidemannstr. 14<br />
                                        51067 Köln <br />
                                    </p>
                                    <p> <strong>Vertreten durch: </strong><br />
                                        Christoph Landsky<br />
                                    </p>
                                    <p><strong>Kontakt:</strong> <br />
                                        Telefon: 0177-4565662<br />
                                        E-Mail: <a href='mailto:info@christophlandsky.de'>info@christophlandsky.de</a><br /></p>
                                    <p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong><br />
                                        Christoph Landsky <br />
                                        Scheidemannstr. 14<br />
                                        51067 Köln <br /></p>
                                    <p><strong>Haftungsausschluss: </strong><br /><br /><strong>Haftung für Inhalte</strong><br /><br />
                                        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
                                        Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
                                        Abs.1
                                        TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
                                        sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen
                                        zu
                                        überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen
                                        zur
                                        Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
                                        unberührt.
                                        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                                        möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
            entfernen.<br /><br /><strong>Haftung
                für Links</strong><br /><br />
                                        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                                        Deshalb
                                        können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
                                        stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                                        Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
                                        der
                                        Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                                        konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                                        werden
            wir derartige Links umgehend entfernen.<br /><br /><strong>Urheberrecht</strong><br /><br />
                                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                                        Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                                        Grenzen
                                        des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads
                                        und
                                        Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte
                                        auf
                                        dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                                        werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
                                        aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                                        werden
                                        wir derartige Inhalte umgehend entfernen.<br /><br /></p>
                                    Website Impressum von <a href="https://www.impressum-generator.de">impressum-generator.de</a>
                                </Container>
                            </div>
                        </article>
                    </div>
                </div>
            </div >
        );
    }
}