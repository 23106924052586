import React, {Component} from "react";
import {Container} from "reactstrap";
import Header from "../components/Header";
import "./aboutpage.scss"

export default class MediaPage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.revealText = this.revealText.bind(this)
        this.unrevealText = this.unrevealText.bind(this)
    }

    stoppedTyping() {
        this.setState({animClass: "subtitle fadein"});
    }

    revealText(number, e) {
        e.target.innerText = this.state.links[number].name;
    }

    unrevealText(number, e) {
        e.target.innerText = this.state.links[number].encryptedName;
    }

    render() {
        return (
            <div>
                <Header name={"Medien"}/>
                <div id="music" className="content fade-in top-space-for-header">
                    <div id="main">
                        <article id="foto">
                            <div className="about-content container-fluid bg-b">
                                <Container>
                                    <h2>Foto</h2>
                                    <p>Comming soon...</p>
                                    <a href="https://photo.clsky.de" target="_blank" rel="noreferrer">Fotogalerie</a>
                                </Container>
                            </div>
                        </article>
                        <article id="film">
                            <div className="about-content container-fluid bg-a">
                                <Container>
                                    <h2>Film</h2>
                                    <p>Comming soon...</p>
                                </Container>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        );
    }
}


