import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import "bootstrap/dist/css/bootstrap.min.css"
import './styles/index.scss';
import AboutMePage from './Main/pages/AboutMePage';
import * as serviceWorker from './serviceWorker';
import HomePage from "./Main/pages/HomePage";
import {HashRouter} from "react-router-dom";
import {Redirect, Route, Switch} from "react-router";
import MusicPage from "./Main/pages/MusicPage";
import SoftwarePage from "./Main/pages/SoftwarePage";
import PublishedPage from "./Main/pages/PublishedPage";
import MediaPage from "./Main/pages/MediaPage";


// const routes = {
//     '/': () => <HomePage/>,
//     '/about': () => <AboutMePage/>
//     // '/products/:id': ({id}) => <ProductDetails id={id} />
// };
//
// const AppRouter = () => {
//     const routeResult = useRoutes(routes);
//     return routeResult || <HomePage/>;
// }

class App extends Component {

    render() {
        return (
            <div className="App">
                <div className={"content background"}>
                    <HashRouter>
                        <Switch>
                            <Route path="/home" component={HomePage}></Route>
                            <Route path="/about" component={AboutMePage}></Route>
                            <Route path="/music" component={MusicPage}></Route>
                            <Route path="/software" component={SoftwarePage}></Route>
                            <Route path="/published" component={PublishedPage}></Route>
                            <Route path="/media" component={MediaPage}></Route>
                            <Redirect from="/" to="home" />
                        </Switch>
                    </HashRouter>


                </div>
            </div>
        );
    }
}

ReactDOM.render(<App/>, document.getElementById('root'));


//<div className="player-overlay">
//                             <AudioPlayer />
//                         </div>
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();