import React, {Component} from 'react';
import Icon from '@mdi/react'
import {Col, Row} from "reactstrap";
import {mdiArrowLeft} from "@mdi/js";

class Header extends Component {
    render() {
        return (
            <div className="back-button">
                <Row>
                    <Col xs={"1"}>
                        <a href={"#/home"} className={"back-button-link"}>
                            <Icon path={mdiArrowLeft} size={1} color={"white"}/>
                        </a>
                    </Col>
                    <Col xs={"10"}>
                        <div className="txt-center-top">{this.props.name}</div>
                    </Col>
                    <Col xs={"1"}>

                    </Col>

                </Row>
            </div>

        );
    }
}

export default Header;