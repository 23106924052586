import React, {Component} from 'react';
import Icon from '@mdi/react'

class ListElement extends Component {
    render() {
        return (
            <div onMouseEnter={() => this.props.enterHandler(this.props.name)} onMouseLeave={() => this.props.leaveHandler()}>
                <li className="icon-list-item">
                    <a className="aicon" href={this.props.href} target={this.props.target}
                       rel="noopener noreferrer">
                        <Icon path={this.props.iconClass} size={this.props.size} color={"white"}/>
                    </a>
                </li>
            </div>

        );
    }
}

export default ListElement;