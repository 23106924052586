import React, {Component} from "react";
import {Container} from "reactstrap";
import Header from "../components/Header";
import "./aboutpage.scss"

export default class MusicPage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.revealText = this.revealText.bind(this)
        this.unrevealText = this.unrevealText.bind(this)
    }

    stoppedTyping() {
        this.setState({animClass: "subtitle fadein"});
    }

    revealText(number, e) {
        e.target.innerText = this.state.links[number].name;
    }

    unrevealText(number, e) {
        e.target.innerText = this.state.links[number].encryptedName;
    }

    render() {
        return (
            <div>
                <Header name={"My Music"}/>
                <div id="music" className="content fade-in top-space-for-header bg-a">
                    <img style={{width: "100%"}} src="img/hf4.jpg" className="img-seperator" alt={"Nope"}></img>
                    <div id="main">
                        <article id="crashtest">

                            <div className="about-content container-fluid bg-b">
                                <Container>
                                    <h2>Crashtest</h2>
                                    <div className="cite">
                                        <i>"Die Band CRASHTEST ist der musikalisch lebendig gewordene Autounfall.
                                            (Song-)Teile fliegen durcheinander
                                            und können ernsthafte Verletzungen am Kopf verursachen. Somit ist es nicht
                                            verwunderlich, dass
                                            man dies nicht direkt mit den Menschen, sondern an drei Crashtestdummies,
                                            Nik-0
                                            (Bremstrommel),
                                            Fr4nk (Hubraum), Chr15 (Auspuff) testet. Ihr Auftrag: Anecken bis der
                                            Schrotthändler kommt!"</i>
                                        <p className="cite-footer"> - Crashtest: Offizielle Bandbeschreibung</p>
                                    </div>
                                    <p>Crashtest ist eine experimentelle Band bestehend aus Gesang, Bass und Schlagzeug.
                                        Die
                                        Gitarre kommt aus
                                        einem Sampleplayer und wird über einen Gitarrenverstärker abgenommen.
                                    </p>
                                </Container>
                            </div>
                        </article>

                        <article id="haertefall">

                            <div className="about-content container-fluid bg-a">
                                <Container>
                                    <h2>Haertefall</h2>


                                    <div className="cite">
                                        <i>"Schnell, hart, laut... Willkommen in der Welt von Haertefall... Heutzutage
                                            ist
                                            es schwer, die Maßstäbe
                                            der Menschheit zu definieren. Doch das hält die Recken von Haertefall nicht
                                            davon ab, lyrisch
                                            als auch musikalisch ihre Umgebung zu kommentieren. In diesem Fall trifft
                                            harte
                                            Gesellschaftskritik
                                            auf donnernde Gitarrensalven und mörderische Keyboardarrangements, die im
                                            Groben
                                            und Ganzen in
                                            der Kategorie Industrial-/Darkmetal einzuordnen sind... Die Texte sind
                                            ausschließlich in Deutsch
                                            verfasst. Haertefall besteht aus einer klassischen Metalbesetzung, sprich
                                            zwei
                                            Gitarren (Joshi
                                            Wadenpohl & Maurice Budke), ein Sänger (Christoph Landsky), ein Schlagzeuger
                                            (Niklas Odenthal),
                                            ein Bass (Frank Pannes) und ein Keyboarder (Niklas Enns)... Dies macht die
                                            Band
                                            nicht nur im
                                            Studio akustisch flexibel, sondern verleiht auch jedem Konzert durch die
                                            Akzentuierung des Keyboards
                                            einen gewissen klanglichen Charme. High Definition Metal aus Deutschland:
                                            Haertefall"</i>
                                        <p className="cite-footer"> - Haertefall: Offizielle Bandbeschreibung</p>
                                    </div>
                                </Container>
                            </div>
                        </article>
                        <article id="solo">
                            <div className="about-content container-fluid bg-b">
                                <Container>
                                    <h2>Solo-Projekte</h2>
                                    <p>Comming soon...</p>
                                </Container>
                            </div>
                        </article>
                        <article id="gear">
                            <div className="about-content container-fluid bg-a">
                                <Container>
                                    <h2>Aktuelles Gear</h2>
                                    <h5>Studio:</h5>
                                    <ul className="custom-list">
                                        <li>Cubase 8</li>
                                        <li>Rode NT1-A</li>
                                        <li>Steinberg UR22</li>
                                    </ul>
                                    <h5>Live:</h5>
                                    <ul className="custom-list">
                                        <li>Shure FP2/SM58 Wireless</li>
                                    </ul>
                                </Container>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        );
    }
}


