import React, { Component } from 'react';
import './homepage.scss'
import Typed from 'react-typed';
import {
    mdiAccountTie,
    mdiBookshelf,
    mdiCamera,
    mdiCodeJson,
    mdiGitlab,
    mdiHeadphones,
    mdiSoundcloud,
    mdiYoutube
} from '@mdi/js'
import ListElement from "../components/ListElement";

class HomePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            application: "CLSKY",
            openInfo: false
        }
        this.show = this.show.bind(this)
        this.hideAll = this.hideAll.bind(this)

    }


    show(appname) {
        this.setState({ application: appname })
        this.typed.reset(true)
    }

    hideAll() {
        this.setState({ application: "CLSKY" })
        this.typed.reset(true)
    }


    render() {
        return (
            <div>
                <div className="text-center fade-in" style={{ marginBottom: "0" }}>
                    <div id="title" className="centered-wsb justify-content-center text-light">
                        <h1 className={"home-applications"}>
                            {"$ "}<Typed className="title-sm" typedRef={(typedInstance) => {
                                this.typed = typedInstance;
                            }}
                                strings={[this.state.application.toLowerCase()]}
                                typeSpeed={40} />
                        </h1>

                        <ul className="nav aicon-bar justify-content-center" style={{ fontSize: "42px" }}>
                            <ListElement name="About_Me" enterHandler={this.show} leaveHandler={this.hideAll}
                                iconClass={mdiAccountTie}
                                href="#/about" target="" size={1.8} />
                            <ListElement name="Software" enterHandler={this.show} leaveHandler={this.hideAll}
                                iconClass={mdiCodeJson}
                                href="#/software" size={1.8} />
                            <ListElement name="Media" enterHandler={this.show} leaveHandler={this.hideAll}
                                iconClass={mdiCamera} href="#/media" size={1.8} />
                            <ListElement name="Music" enterHandler={this.show} leaveHandler={this.hideAll}
                                iconClass={mdiHeadphones}
                                href="#/music" size={1.8} />
                            <ListElement name="Published" enterHandler={this.show} leaveHandler={this.hideAll}
                                iconClass={mdiBookshelf}
                                href="https://ghostnode-music.de" size={1.8} />

                        </ul>
                        <ul className="nav justify-content-center" style={{ fontSize: "42px" }}>
                            <ListElement name="Gitlab" enterHandler={this.show} leaveHandler={this.hideAll}
                                iconClass={mdiGitlab} href="https://gitlab.com/clandsky"
                                target="_blank" size={1} />
                            <ListElement name="Youtube" enterHandler={this.show} leaveHandler={this.hideAll}
                                iconClass={mdiYoutube}
                                href="https://www.youtube.com/user/ChristophLandsky" target="_blank" size={1} />
                            <ListElement name="Soundcloud" enterHandler={this.show} leaveHandler={this.hideAll}
                                iconClass={mdiSoundcloud}
                                href="https://www.soundcloud.com/clandsky" target="_blank" size={1} />

                        </ul>
                    </div>
                </div>

            </div>
        );
    }
}

export default HomePage;
